<template>
	<div class="flex items-center justify-between w-full">
		<Text color="black" weight="bold" size="2xl" customClass="sm:text-2xl" :content="title"/>
		<ButtonWithIcon v-if="btnText" :click="() => $emit('onClick')" :label="btnText" iconName="PlusIcon" variant="primary" leading size="md" class="h-10 sm:h-10"/>
	</div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import ButtonWithIcon from "../../Button/WithIcon/WithIcon.vue";

export default {
	components: {
		Text,
		ButtonWithIcon,
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		btnText: {
			type: String,
			default: ''
		},
	}

}
</script>
