<template>
  <SideOver :title="title" :is-show="isShow" :label="content" :submitBtnText="submitBtnText" @onClose="() => $emit('onClose')" @onSubmit="() => onSubmit()" :isLoading="isLoading">
    <div class="flex-1 h-full p-6 space-y-4 overflow-y-auto">
      <div v-for="item in formGroup">
        <Select v-if="item.type==='select'" :label="item.label" :items="list" customClass="w-full"
                v-model:selectedItem="item.selectedItem"/>
        <Input v-else :label="item.label" :placeholder="item.placeholder" :type="item.type" v-model:value="item.value"/>
      </div>
    </div>
  </SideOver>
</template>

<script>
import Text from '../../../atoms/Text/Text.vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Select from '../../../molecules/Selects/Component/Component.vue'
import Button from '../../../atoms/Button/Button.vue'
import SideOver from '../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue'

export default {
  components: {
    Text,
    Icon,
    Input,
    Select,
    Button,
    SideOver
  },

  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    formGroup: {
      type: Array,
      default: () => []
    },
    buttons: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    submitBtnText: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onSubmit() {
      console.log('onSubmit')
      this.$emit('onSubmit', this.formGroup)
    }
  }
}
</script>
