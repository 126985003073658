<template>
	<div class="px-6 py-5 border border-dashed flex space-x-4 relative" :class="isFull ? '' : 'max-w-lg'">
		<Icon :name="icon" size="7" color="gray-600"/>
		<div class="mt-1">
			<Text size="" weight="bold" :content="title" color="gray-600"/>
			<Text size="sm" :content="description" color="gray-500" customClass="mt-2"/>
			<Button variant="success" :content="btnText" :onClick="action" customClass="mt-7"/>
		</div>
		<a class="absolute right-4 top-4" @click="$emit('onClose')"><Icon name="XIcon" color="black" size="6" isOutline/></a>
	</div>
</template>

<script>
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'
/**
 * - Use it to show a simple modal.
 */
export default {
	components: {
		Icon, Button, Text
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		description: {
			type: String,
			default: ''
		},
		btnText: {
			type: String,
			default: ''
		},
		icon: {
			type: String,
			default: ''
		},
		action: {
			type: Function,
			default: () => {}
		},
		isFull: {
			type: Boolean,
			default: false,
		}
	},
}
</script>
